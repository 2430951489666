import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useDevice } from 'hooks/useDevice';

import { SectionStyle, ProfilPictureStyle, SubDivStyle, LabelStyle, TextStyle, ButtonStyle } from 'pages/home/home.style';

import { UserConsumerHook } from 'stores/userStore';
import { getUser } from 'core/twitchAPI.js';
import useAccessToken from 'hooks/useAccessToken';

function Home() {
  const { isMobile } = useDevice();
  const [{ user, OAuth, isSub }, dispatch] = UserConsumerHook();
  const { accessToken } = useAccessToken();
  const labelSub = isSub ? 'tu es Sub' : 'tu n\'es pas Sub';

  useEffect(() => {
    getUser(dispatch, accessToken, OAuth);
  }, [dispatch, accessToken, OAuth]);

  return (
    <Section className="home" isMobile={isMobile}>
      <ProfilPicture src={user?.profile_image_url} />
      <Label isMobile={isMobile}>{user?.display_name} {labelSub} à MaucSama !</Label>
      {isSub ?
        <SubDiv>
          <Text isMobile={isMobile}>Cliques sur le bouton pour continuer :</Text>
          <Button>Panel</Button>
        </SubDiv>
        :
        <Text isMobile={isMobile}>Pour continuer tu dois être sub à MaucSama, à bientôt 👌</Text>
      }
    </Section>
  );
}

const Section = styled.section`
  ${SectionStyle};
`;

const ProfilPicture = styled.img`
  ${ProfilPictureStyle};
`;

const Label = styled.h2`
  ${LabelStyle};
`;

const SubDiv = styled.div`
  ${SubDivStyle};
`;

const Text = styled.p`
  ${TextStyle};
`;

const Button = styled.button`
  ${ButtonStyle};
`;

export default Home;
