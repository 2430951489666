import React, { createContext, useContext, useReducer } from 'react';

const initialState = {
  user: null,
  OAuth: null,
  isSub: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'cleanUser':
      return {
        ...state,
        user: null,
        isSub: null,
      };
    case 'changeUser':
      return {
        ...state,
        user: action.newUser,
      };
    case 'changeOAuth':
      return {
        ...state,
        OAuth: action.newOAuth,
      };
    case 'changeIsSub':
      return {
        ...state,
        isSub: action.newIsSub,
      };
    default:
      return state;
  }
};

const UserContext = createContext();

export const UserConsumer = UserContext.Consumer;
export const UserConsumerHook = () => useContext(UserContext);

export const UserProvider = ({ children }) => (
  <UserContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </UserContext.Provider>
);
