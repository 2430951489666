import { css } from 'styled-components';
import Back from 'assets/back.png';

export const SectionStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.3)
  ), url(${Back});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
`;

export const LabelStyle = css`
  font-size: ${(p) => (p.isMobile ? 30 : 40)}px;
  font-family: Arial;
  color: white;
  text-shadow: 0px 0px 4px #ffffff;
  text-align: center;
  margin: 0;
`;

export const ButtonStyle = css`
  margin-top: 30px;

  font-size: ${(p) => (p.isMobile ? 15 : 20)}px;
  font-family: Arial;
  color: black;
  background-color: rgba(255, 255, 255, .5);
  border: 0;
  padding: 8px;
  border-radius: 20px 5px 10px 5px;
  text-shadow: 0px 0px 4px #ffffff;
  &:hover {
    transform: scale(1.1);
    background-color: rgba(255, 255, 255, .8);
  }
  transition: all ease-in-out .5s;
`;