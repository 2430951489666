import axios from 'axios';
import config from 'config.json';

export async function OAuthTwitch(dispatch) {
  const twitchAPI =
    'https://id.twitch.tv/oauth2/token?client_id=' +
    config.clientID +
    '&client_secret=' +
    config.clientSecret +
    '&grant_type=' +
    config.grantType;
  axios
    .post(twitchAPI)
    .then((response) => {
      dispatch({
        type: 'changeOAuth',
        newOAuth: response?.data?.access_token,
      });
      console.log(response)
    })
    .catch((error) => {
      console.log('error', error);
    });
}

export async function getUser(dispatch, OAuth) {
  const twitchApi = 'https://api.twitch.tv/helix/users';
  axios
    .get(twitchApi, {
      headers: {
        'client-id': config.clientID,
        Authorization: 'Bearer ' + OAuth,
      },
    })
    .then((response) => {
      dispatch({
        type: 'changeUser',
        newUser: response?.data?.data[0],
      });
      getIfSub(dispatch, OAuth, response?.data?.data[0]?.id)
    })
    .catch((error) => {
      console.log('error', error);
    });
}

export async function getIfSub(dispatch, OAuth, userId) {
  const twitchApi = 'https://api.twitch.tv/helix/subscriptions/user?broadcaster_id=' +
    config?.maucsamaID + '&user_id=' + userId;
  axios
    .get(twitchApi, {
      headers: {
        'client-id': config.clientID,
        Authorization: 'Bearer ' + OAuth,
      },
    })
    .then((response) => {
      dispatch({
        type: 'changeIsSub',
        newIsSub: response?.data?.data[0],
      });
    })
    .catch((error) => {
      console.log('error', error);
    });
}


export async function getStream(dispatch, OAuth, streamerId) {
  const twitchApi = 'https://api.twitch.tv/helix/streams?user_id=' + streamerId;
  axios
    .get(twitchApi, {
      headers: {
        'client-id': config.clientID,
        Authorization: 'Bearer ' + OAuth,
      },
    })
    .then((response) => {
      dispatch({
        type: 'changeStream',
        newStream: response?.data?.data[0],
      });
    })
    .catch((error) => {
      console.log('error', error);
    });
}
