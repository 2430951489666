import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import config from 'config.json';

import { useDevice } from 'hooks/useDevice';

import { SectionStyle, LabelStyle, ButtonStyle } from 'pages/login/login.style';

export default function Login({ setAccessToken }) {
  const { isMobile } = useDevice();

  const onClickOnTwitch = () => {
    var response_type = 'token';
    window.location.href = `https://id.twitch.tv/oauth2/authorize?response_type=${response_type}&client_id=${config?.clientID}&redirect_uri=${config?.fullRedirectUri}&scope=user:read:subscriptions`
  }

  useEffect(() => {
    if (document?.location?.hash) {
      var hash = window.location.hash.substring(1);
      var params = {}
      hash.split('&').map(hk => {
        let temp = hk.split('=');
        params[temp[0]] = temp[1]
      });
      setAccessToken(params?.access_token);
    }
  }, [document.location.hash]);

  return (
    <Section isMobile={isMobile}>
      <Label isMobile={isMobile}>Merci de te connecter avec Twitch</Label>
      <Button isMobile={isMobile} onClick={onClickOnTwitch}>Connexion à Twitch</Button>
    </Section>
  )
}

Login.propTypes = {
  setAccessToken: PropTypes.func.isRequired
};

const Section = styled.section`
  ${SectionStyle};
`;

const Label = styled.h2`
  ${LabelStyle};
`;

const Button = styled.button`
  ${ButtonStyle};
`;