import { useState } from 'react';

export default function useAccessToken() {
  const getAccessToken = () => {
    const accessTokenString = localStorage.getItem('accessToken');
    const userAccessToken = JSON.parse(accessTokenString);
    return userAccessToken
  };

  const [accessToken, setAccessToken] = useState(getAccessToken());

  const saveAccessToken = userAccessToken => {
    localStorage.setItem('accessToken', JSON.stringify(userAccessToken));
    setAccessToken(userAccessToken);
  };

  return {
    setAccessToken: saveAccessToken,
    accessToken
  }
}