import { css, keyframes } from 'styled-components';
import Back from 'assets/back.png';

const float = keyframes`
  0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.02);
	}
	100% {
		transform: scale(1);
	}
`;

const animate = keyframes`
  0% {
    background-position-y: 0;
  }
  100% {
    background-position-y: -480px;
  }
`

export const SectionStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.3)
  ), url(${Back});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const LabelStyle = css`
  font-size: ${(p) => (p.isMobile ? 15 : 40)}px;
  font-family: Arial;
  color: white;
  text-shadow: 0px 0px 4px #ffffff;
`;

export const TextStyle = css`
  font-size: ${(p) => (p.isMobile ? 15 : 20)}px;
  font-family: Arial;
  color: white;
  text-shadow: 0px 0px 4px #ffffff;
`;

export const SubDivStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonStyle = css`
  position: relative;
  width: 180px;
  height: 60px;
  margin: 20px;
  line-height: 60px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  transition: 0.5s;
  border: 1px solid #7a18ec;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  &:hover {
    border: 1px solid transparent;
    background: #7a18ec url(https://i.postimg.cc/FzBWFtKM/pixel2.png); // 360px x 1080px
    transition-delay: 0.8s;
    background-size: 180px;
    animation-name: ${animate};
    animation-duration: 0.8s;
    animation-iteration-count: forwards;
  }
`;

export const ProfilPictureStyle = css`
  width: 200px;
  height: 200px;
  border-radius: 100%;
  filter: drop-shadow(5px 5px 10px rgba(0,0,0, 1));
  animation-name: ${float};
  animation-duration: 5s;
  animation-iteration-count: infinite;
  margin-bottom: 30px;
`;
