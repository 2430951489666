import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';

import { createGlobalStyle, keyframes } from 'styled-components';

import Home from 'pages/home/home';
import Login from 'pages/login/login';
import Dashboard from 'pages/dashboard/dashboard';

import { useDevice } from 'hooks/useDevice';
import useAccessToken from 'hooks/useAccessToken';
import { UserProvider } from 'stores/userStore';

import Back from 'assets/back.png';

function App() {
  const { isMobile } = useDevice();
  const { accessToken, setAccessToken } = useAccessToken();

  if (!accessToken) {
    return <Login setAccessToken={setAccessToken} />
  }

  return (
    <div className="App">
      <GlobalStyle isMobile={isMobile} />
      <UserProvider>
        <Router>
          <Routes>
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>
        </Router>
      </UserProvider>
    </div>
  );
}

const float = keyframes`
  0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.03);
	}
	100% {
		transform: scale(1);
	}
`;

const GlobalStyle = createGlobalStyle`
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style-type: none;
}

:root {
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.3)
  ), url(${Back});
}

section {
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.3)
  ), url(${Back});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow-x: hidden;
  overflow-y: 'scroll';

  h1 {
    font-family: Norwester;
    font-size: 100px;
    color: #f05183;
    text-shadow: 8px 8px 0px #642d5a;
    animation-name: ${float};
    animation-duration: 5s;
    animation-iteration-count: infinite;
  }

  padding: ${(p) => (p.isMobile ? '10px 30px' : '20px 100px')};
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(240, 81, 131, 0.7);
  border-radius: 4px;
  width: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #642d5a;
}

/*@font-face {
  font-family: 'Norwester';
  src: url(${process.env.PUBLIC_URL + '/assets/fonts/Norwester.woff2'}) format('woff2'),
    url(${process.env.PUBLIC_URL + '/assets/fonts/Norwester.woff'}) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url(${process.env.PUBLIC_URL + '/assets/fonts/Montserrat-Regular.woff2'}) format('woff2'),
    url(${process.env.PUBLIC_URL + '/assets/fonts/Montserrat-Regular.woff'}) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}*/
`;

export default App;
